import React from 'react';
import {graphql} from 'gatsby';
import {Helmet} from 'react-helmet';
import Layout from '../components/Layout';
import styles from './styles/info.module.scss';

const InfoPage = ({data}) => (
    <>
        <Helmet>
            <title>Info : {data.site.siteMetadata.title}</title>
            <meta
                name="description"
                content={`Info : ${data.site.siteMetadata.description}`}
            />
            <meta
                name="og:title"
                content={`Info : ${data.site.siteMetadata.title}`}
            />
            <meta
                name="og:description"
                content={`Info : ${data.site.siteMetadata.description}`}
            />
            <meta
                name="og:url"
                content={`${data.site.siteMetadata.url}/info`}
            />
            <meta name="og:type" content="website" />
        </Helmet>
        <Layout>
            <div className={styles.container}>
                <aside
                    className={styles.sidebar}
                    dangerouslySetInnerHTML={{__html: data.contentfulPageContent.sidebar.childMarkdownRemark.html}}
                />
                <main
                    className={styles.main}
                    dangerouslySetInnerHTML={{__html: data.contentfulPageContent.body.childMarkdownRemark.html}}
                />
            </div>
        </Layout>
    </>
);

export default InfoPage;

export const query = graphql`
query {
  site {
    ...SiteMetadata
  }
  contentfulPageContent(title: {eq: "Info"}) {
    body {
      childMarkdownRemark {
        html
      }
    }
    sidebar {
      childMarkdownRemark {
        html
      }
    }
  }
}  
`;
